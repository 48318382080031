$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';

.container {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  width: 100%;
  height: 100%;
}

.buttonWrapper {
  @include stickyButtonsWrapper;

  display: flex;
  gap: var(--b-space-m);
  justify-content: flex-end;

  padding: var(--b-space-l) 0 var(--b-space-xl) 0;

  @media #{$fromLaptop}{
    padding: var(--b-space-xl) 0 ;

    button {
     flex-basis: 50%;
    }
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  width: 100%;
  height: 100%;

  @media #{$fromLaptop} {
    grid-template-rows: 1fr;
  }
}
