$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/common';

.container {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: row;
  align-items: center;

  border-radius: #{toRem(8)};
  padding: var(--b-space-xxs) var(--b-space-xs);

  background-color: var(--b-color-support-error-light);
  color: var(--b-color-text-accelerator-red);
}

.viewingNow {
  background-color: var(--b-color-tag-accelerator);
  color: var(--b-color-text-dark);
}
